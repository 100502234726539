/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
const $ = require('jquery');
global.$ = global.jQuery = $;
window.$ = $;

import { Toast, Modal } from 'bootstrap';


import AOS from 'aos/dist/aos'
AOS.init();

// any CSS you import will output into a single css file (app.css in this case)
import 'aos/dist/aos.css'
import './styles/global.scss';

const toastElList = [].slice.call(document.querySelectorAll('.toast'))
const toastList = toastElList.map(function (toastEl) {
    const toast = new Toast(toastEl, {})
    toast.show();
    return toast;
})